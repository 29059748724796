@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Tamil:wght@300;400;500;600;700&display=swap');

body{
  background-color: #F0F3F4;
  font-family: 'Source Sans Pro', sans-serif;
}

.nav-link{
    font-size: 15px;
    color: #FFFFFF;
    font-weight: 600;
}

.stat-card{
  border: none;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
}

.card-tit{
  font-size: 16px;
  color: #808080;
}

.card-texts{
  font-size: 30px;
  color: #58666E;
  margin: 0px;
}

.table-title{
  font-size: 14px;
  color: #555555;
  font-weight: 600;
}

.balance{
  font-size: 15px;
  color: #e10505;
  font-weight: 400;
}

.total{
  font-size: 15px;
  color: #02b326;
  font-weight: 400;
}

.table-data{
  color: #666666;
  font-size: 15px;
  font-weight: 400;
}

#navbar{
  background-color: #FFFFFF;
  /* border-bottom: 0.5px solid #002970; */
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
}

.brand-Btn{
  background-color: #002970;
  color: #FFFFFF;
}

.row{
  margin-left: 5px;
  margin-right: 5px;
  margin-top : 10px;
}

.brand-Btn:hover{
  color: #082352;
}

.add-btn{
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 10px;
  border: 1px solid #F9F9F9;
}

.add-btn:hover{
  color: #000000;
}

.form-select , .form-control{
  border-radius: 8px;
  border: 1.5px solid #b5b4b4;
}

.form-select:focus , .form-control:focus , .btn:focus{
  box-shadow: none;
}

.brand-Btn:focus{
  
  background-color: #484848;
}

.add-navbar{
  background-color: #FFFFFF;
  /* border-bottom: 0.5px solid #c9c9c9; */
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.265);
}

.customerHover:hover{
  background-color: #f0f0f0d7;
}

.customerHover{
  text-decoration: none;
}

.borderbottom{
  border-bottom: 0.5px solid #e7e7e7;
}

.btnClose{
  background-color: #dfdfdf;
  color: #636363;
  border-radius: 7px;
  font-weight: 600;
}

.showBillBtn{
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 5px;
  font-size: 14px;
  border: 0.5px solid #9d9d9d;
}

.gold{
  background-color: #EBA83A;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  border-radius: 5px;
  width: fit-content;
}

.username{
  padding: 10px 15px;
  background: #ffffff;;
  border: 1px solid #e2e8ea;
  border-radius: 2px;
  border-left: 4px solid;
  border-left-color: rgb(94, 190, 91);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.177);
}

.last-box-warn{
  padding: 10px 15px;
  background: #ffffff;;
  border: 1px solid #e2e8ea;
  border-radius: 2px;
  border-left: 4px solid;
  border-left-color: rgb(227, 142, 53);
  box-shadow: 0 1px 4px 0 rgb(0, 0, 0, 0.177);
}

.last-box{
  padding: 10px 15px;
  background: #ffffff;;
  border: 1px solid #e2e8ea;
  border-radius: 2px;
  border-left: 4px solid;
  border-left-color: rgb(209, 45, 45);
  box-shadow: 0 1px 4px 0 rgb(0, 0, 0, 0.177);
}

.product-list{
  background-color: #ffffff;
  width: 100%;
height: 85vh;
overflow: scroll;
overflow-x:hidden;
}

.productList{
  background-color: #ffffff;
  width: 100%;
height: 35.7vh;
overflow: scroll;
overflow-x:hidden;
}

.lowproductList{
  background-color: #ffffff;
  width: 100%;
  height: 143vh;
  overflow: scroll;
  overflow-x:hidden;
}

.point-header{
  font-size: 18px;
  color: #58666E;
}

.sale-title{
  font-size: 15px;
  font-weight: 600;
  color: #535353;
}

.paidBox{
  background-color: #002970;
  border-radius: 5px;
  padding: 5px 10px;
  width: fit-content;
}

.customerList{
  background-color: #f8f7f7;
  width: 100%;
height: 16vh;
border-radius: 8px;
overflow: scroll;
overflow-x:hidden;
}

.saleList{
  background-color: #fbfbfb;
  width: 100%;
height: 84vh;
border-radius: 8px;
overflow: scroll;
overflow-x:hidden;
}

.billList{
  background-color: #fbfbfb;
  width: 100%;
height: 78vh;
border-radius: 8px;
overflow: scroll;
overflow-x:hidden;
}

.lowproductList::-webkit-scrollbar{
  display: none;
}

.productList::-webkit-scrollbar{
  display: none;
}

.price-text{
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
}

.price-total{
  font-size: 28px;
  font-weight: 600;
}

.modal-bg{
  background-color: #ebebeb;
}

/*! BILL IN MODAL DESIGN */

.inner-bg{
  background-color: #FFFFFF;
  padding: 15px 0px;
}

/* .inner-border{
  border-width: 2px;
  border-radius: 2px;
  border-color: #000000;
  border-style:dashed;
} */

.bill-img{
  margin-top: 10px;
  margin-bottom: 10px;
}

.billText{
  color: #000000;
  font-weight: 600;
  font-size: 11px;
}

.billTextName{
  color: #000000;
  font-weight: 500;
  font-family: 'Noto Sans Tamil', sans-serif;
  font-size: 9px;
}

.billTextTotal{
  color: #000000;
  font-weight: 800;
  font-size: 15px;
}

.paymentType{
  background-color: #000000;
  width: fit-content;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: 800;
  font-size: 12px;
}

.term{
  color: #000000;
  font-weight: 600;
  font-size: 6px;
}

.loyaltyPoints{
  color: #000000;
  font-weight: 800;
  font-size: 12px;
}

.rateInfo{
  font-size: 12px;
  font-weight: 800;
}

.hr-bill{
  border-top: 0.5px dashed #000000;
}