
.formss{
    margin-top:100px;
}

.login-btn{
    background-color: #232F3E;
    color: #ffffff;
    font-weight: 500;
}

.login-btn:hover{
    background-color: #232F3E;
    color: #ffffff;
    font-weight: 500;
}
#forms{
    
    border: none;
    width: 85%;
    margin: auto;
    box-shadow: none;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(2, 0, 0, 0.13);
    -moz-box-shadow:    0px 0px 25px 0px rgba(2, 0, 0, 0.13);
    box-shadow:         0px 0px 25px 0px rgba(2, 0, 0, 0.13);
}

@media screen and (max-width:900px) {
    #forms{
        width: 93%;
    }
}